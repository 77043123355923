exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogi-js": () => import("./../../../src/pages/blogi.js" /* webpackChunkName: "component---src-pages-blogi-js" */),
  "component---src-pages-kiri-on-saadetud-js": () => import("./../../../src/pages/kiri-on-saadetud.js" /* webpackChunkName: "component---src-pages-kiri-on-saadetud-js" */),
  "component---src-pages-sildid-js": () => import("./../../../src/pages/sildid.js" /* webpackChunkName: "component---src-pages-sildid-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cnt-page-js": () => import("./../../../src/templates/cnt-page.js" /* webpackChunkName: "component---src-templates-cnt-page-js" */),
  "component---src-templates-hupnoteraapia-page-js": () => import("./../../../src/templates/hüpnoteraapia-page.js" /* webpackChunkName: "component---src-templates-hupnoteraapia-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-kehapsuhhoteraapia-page-js": () => import("./../../../src/templates/kehapsühhoteraapia-page.js" /* webpackChunkName: "component---src-templates-kehapsuhhoteraapia-page-js" */),
  "component---src-templates-kinkekaart-page-js": () => import("./../../../src/templates/kinkekaart-page.js" /* webpackChunkName: "component---src-templates-kinkekaart-page-js" */),
  "component---src-templates-kontakt-page-js": () => import("./../../../src/templates/kontakt-page.js" /* webpackChunkName: "component---src-templates-kontakt-page-js" */),
  "component---src-templates-linda-page-js": () => import("./../../../src/templates/linda-page.js" /* webpackChunkName: "component---src-templates-linda-page-js" */),
  "component---src-templates-mer-massaaz-page-js": () => import("./../../../src/templates/mer-massaaz-page.js" /* webpackChunkName: "component---src-templates-mer-massaaz-page-js" */),
  "component---src-templates-naohooldus-page-js": () => import("./../../../src/templates/näohooldus-page.js" /* webpackChunkName: "component---src-templates-naohooldus-page-js" */),
  "component---src-templates-noustamine-page-js": () => import("./../../../src/templates/nõustamine-page.js" /* webpackChunkName: "component---src-templates-noustamine-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-tanatoteraapia-page-js": () => import("./../../../src/templates/tanatoteraapia-page.js" /* webpackChunkName: "component---src-templates-tanatoteraapia-page-js" */),
  "component---src-templates-teenused-page-js": () => import("./../../../src/templates/teenused-page.js" /* webpackChunkName: "component---src-templates-teenused-page-js" */),
  "component---src-templates-teenusetingimused-page-js": () => import("./../../../src/templates/teenusetingimused-page.js" /* webpackChunkName: "component---src-templates-teenusetingimused-page-js" */)
}

